<template>
  <div>

    <b-card no-body class="mb-5">
      <b-card-header header-tab="header" class="p-1" role="tab">
        <b-button block v-b-toggle.accordion-2 variant="link" class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>

      <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('supplier_balance_report.supplier') }}</label>
                <multiselect v-model="supplier"
                             :placeholder="$t('supplier_balance_report.supplier')"
                             label="name" track-by="id"
                             :options="suppliers"
                             :multiple="false"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             @search-change="getSuppliers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{$t('currency')}}</label>
                <select name="" id="currency_id" v-model="filters.currency_id" class="custom-select">
                  <option v-for="row in currencies" :value="row.id" :key="row.id">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('from_date') }}</label>
                <input v-model="filters.from_date" type="date" id="from_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('to_date') }}</label>
                <input v-model="filters.to_date" type="date" id="to_date" class="form-control">
              </div>

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('suppliers_reports.credit_min') }}</label>
                <input v-model="filters.credit_min" type="number" id="credit_min" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('suppliers_reports.credit_max') }}</label>
                <input v-model="filters.credit_max" type="number" id="credit_max" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('suppliers_reports.debit_min') }}</label>
                <input v-model="filters.debit_min" type="number" id="debit_min" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('suppliers_reports.debit_max') }}</label>
                <input v-model="filters.debit_max" type="number" id="debit_max" class="form-control">
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button type="button" @click="doFilter" class="btn btn-primary mt-auto mr-2" id="m_search">
                  {{ $t('display_report') }}
                </button>
                <button type="button" @click="resetFilter" class="btn btn-danger mt-auto mr-2" id="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <div class="row mb-10">
          <div class="col-12 mt-10" id="summaryTableTitle">
            <h4 class="text-center">{{ $t('supplier_balance_report.balances_report_group_by_all') }}</h4>
            <h5 class="text-center">
              <span v-if="date_time_now">{{ $t('time') }} {{ date_time_now }}</span>
            </h5>
            <h5 class="text-center">
              <span v-if="filters.from_date">{{ $t('from') }} {{ filters.from_date }} </span>
              <span v-if="filters.to_date"> {{ $t('to') }} {{ filters.to_date }}</span>
            </h5>
          </div>
          <div class="d-flex col-12 justify-content-end p-4">
            <b-dropdown right :text="$t('export')" class="mr-2 ml-2">
              <download-excel
                  class="dropdown-item"
                  :fetch="getDataList"
                  :fields="json_fields"
                  :name="$t('supplier_balance_report.supplier_balance_report')+'.xls'">
                <i class="la la-file-excel"></i>{{ $t('excel') }}
              </download-excel>
              <button class="dropdown-item" @click="printData('summaryTable', 'print')">
                <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
              </button>
              <button class="dropdown-item" @click="printData('summaryTable', 'pdf')">
                <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
              </button>
            </b-dropdown>

          </div>
        </div>
        <div class="col-12 mt-10">
          <div class="table-responsive">
            <table class="table table-bordered" :id="'summaryTable'">
              <thead>
              <tr id="myRow1">
                <th>{{ $t('supplier_balance_report.code') }}</th>
                <th>{{ $t('supplier_balance_report.account_no') }}</th>
                <th>{{ $t('supplier_balance_report.name') }}</th>
                <th>{{ $t('supplier_balance_report.balance_before') }}</th>
                <th>{{ $t('supplier_balance_report.total_purchase') }}</th>
                <th>{{ $t('supplier_balance_report.total_refund') }}</th>
                <th>{{ $t('suppliers_reports.supplier_balance') }}</th>
                <th>{{ $t('supplier_balance_report.net_purchase') }}</th>
                <th>{{ $t('supplier_balance_report.total_payments') }}</th>
                <th>{{ $t('supplier_balance_report.settlements') }}</th>
                <th>{{ $t('supplier_balance_report.balance') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(row, index) in data" :key="'c'+getRandom()+index">
                <td>{{ row.code }}</td>
                <td>{{ row.account_no }}</td>
                <td>{{ row.name }}</td>
                <td>{{ row.balance_before }}</td>
                <td>{{ row.total_purchase }}</td>
                <td>{{ row.total_refund }}</td>
                <td>
                  <span class="d-block" v-for="(_balance, index) in row.supplier_balance" :key="'bb'+index">{{ _balance }}</span>
                </td>
                <td>{{ row.net_purchase }}</td>
                <td>{{ row.total_payments }}</td>
                <td>{{ row.settlements }}</td>
                <td>{{ row.balance }}</td>
              </tr>

              </tbody>
              <tfoot>

              <tr v-if="dataTotal">
                <td colspan="7">{{ $t('total') }}</td>
                <th>{{ dataTotal.sum_net_purchase }}</th>
                <th>{{ dataTotal.sum_total_payments }}</th>
                <th>{{ dataTotal.sum_settlements }}</th>
                <th>{{ dataTotal.sum_balance }}</th>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="col-12 mt-5 text-center">
          <button class="btn btn-warning" @click="loadMore">
            <b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon>
            {{ $t('load_more') }}
          </button>
        </div>

      </div>
    </div>
    <div class="selector-export" id="selectorExport"></div>
    <!--end::balance-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";

export default {
  name: "index-supplier-balances",
  components: {},
  data() {
    return {
      mainRoute: '/reports/purchases/supplier/balance',
      // mainRouteExport:'/reports/supplier-balance-report/data-list',
      mainRouteDependency: 'base/dependency',

      date_time_now: null,
      data: [],
      dataTotal: [],
      suppliers: [],
      supplier: null,

      filters: {
        supplier_id: null,
        from_date: null,
        to_date: null,

        currency_id: null,
        credit_min: null,
        credit_max: null,
        debit_min: null,
        debit_max: null,
      },
      page: 0,
      currencies: [],
    }
  },
  watch: {
    supplier: function (val) {
      if (val) {
        this.filters.supplier_id = val.id;
      } else {
        this.filters.supplier_id = null;
      }
    },
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('supplier_balance_report.code')] = 'code';
      fields[this.$t('supplier_balance_report.account_no')] = 'account_no';
      fields[this.$t('supplier_balance_report.name')] = 'name';
      fields[this.$t('supplier_balance_report.balance_before')] = 'balance_before';
      fields[this.$t('supplier_balance_report.total_purchase')] = 'total_purchase';
      fields[this.$t('supplier_balance_report.total_refund')] = 'total_refund';
      fields[this.$t('suppliers_reports.supplier_balance')] = 'supplier_balance';
      fields[this.$t('supplier_balance_report.net_purchase')] = 'net_purchase';
      fields[this.$t('supplier_balance_report.total_payments')] = 'total_payments';
      fields[this.$t('supplier_balance_report.settlements')] = 'settlements';
      fields[this.$t('supplier_balance_report.balance')] = 'balance';

      return fields;
    },

  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t('MENU.purchases_reports'), route: '/reports/purchase-reports-links'}, {title: this.$t("MENU.suppliers_balance_reports")}]);
    this.date_time_now = this.$moment().format('DD-MM-YYYY H:mm');

  },
  created() {
    this.getCurrencies();
  },
  methods: {
    loadMore() {
      this.page = this.page + 1;
      this.getData();
    },

    doFilter() {
      this.getData();
    },
    resetFilter() {
      // this.filters.supplier_id = null;
      // this.supplier = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.currency_id = null;
      this.filters.credit_min = null;
      this.filters.credit_max = null;
      this.filters.debit_min = null;
      this.filters.debit_max = null;
      this.getData();
    },

    dataReport() {
      return this.data;
    },

    getData() {
      let _filter = this.filters;
      if (_filter.supplier_id)
        ApiService.get(this.mainRoute, {params: {..._filter, page: (this.page ? this.page : 1)}}).then((response) => {
          this.data = response.data.data.data;
          this.dataTotal = response.data.data.total;
        });
      else
        this.$errorAlertMessage(this.$t('you_should_select_supplier_to_display_the_report'));
    },

    getSuppliers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/suppliers", {params: {filter: filter}}).then((response) => {
          this.suppliers = response.data.data;
        });
    },
    getCurrencies() {
      ApiService.get(this.mainRouteDependency + "/currencies").then((response) => {
        this.currencies = response.data.data;
        let _currency = response.data.data.find(row => row.code == 'SAR');
        if(_currency){
          this.filters.currency_id = _currency.id;
        }
      });
    },
    getDataList() {
      this.setReportLog('excel', 'suppliers balance reports');
      let _data = [...this.data];
      _data.push({
        code: this.$t('total'),
        account_no: '',
        name: '',
        balance_before: '',
        total_purchase: '',
        total_refund: '',
        supplier_balance: '',
        net_purchase: this.dataTotal.sum_net_purchase,
        total_payments: this.dataTotal.sum_total_payments,
        settlements: this.dataTotal.sum_settlements,
        balance: this.dataTotal.sum_balance,
      });
      return _data;
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    printData(tableId, type) {
      this.setReportLog('pdf', 'suppliers balance reports');
      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('supplier_balance_report.supplier_balance_report'), this.$t('supplier_balance_report.supplier_balance_report'));

    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}

</style>